import React from 'react';
import { BrowserRouter, Route} from 'react-router-dom';

import DataInputSectionsContainer from 'settings/components/DataInputSectionsContainer';
import DataInputRiskEntityDefinitionsContainer from 'settings/components/DataInputRiskEntityDefinitionsContainer';
import DiredSearchResultsContainer from 'settings/components/DiredSearchResultsContainer';
import RiskCategoryOverridesContainer from 'settings/components/RiskCategoryOverridesContainer';
import RiskAttributeSetupContainer from 'settings/components/attribute_wizard/RiskAttributeSetupContainer';
import RiskAttributeIndicatorsContainer from 'settings/components/RiskAttributeIndicatorsContainer';
import RiskAttributeIndicatorSetupContainer from 'settings/components/kri_wizard/RiskAttributeIndicatorSetupContainer';
import RiskAttributeIndicatorSearchContainer from 'settings/components/RiskAttributeIndicatorSearchContainer';
import RiskAttributeIndicatorNewForm from 'settings/components/RiskAttributeIndicatorNewForm';
import RiskAttributeIndicatorEditForm from 'settings/components/RiskAttributeIndicatorEditForm';
import RiskAttributesContainer from 'settings/components/RiskAttributesContainer';
import SelfAssessmentConsiderationsContainer from 'settings/components/SelfAssessmentConsiderationsContainer';
import SelfAssessmentConsiderationSetupContainer from 'settings/components/self_assessment_consideration_wizard/SelfAssessmentConsiderationSetupContainer';
import CallReportsSyncButton from 'settings/components/CallReportsSyncButton';
import DepartmentRiskEntityDefinitionsContainer from 'administration/components/DepartmentRiskEntityDefinitionsContainer';
import RiskAttributeIndicatorDefinitionNewForm from 'administration/components/RiskAttributeIndicatorDefinitionNewForm';
import RiskAttributeIndicatorDefinitionEditForm from 'administration/components/RiskAttributeIndicatorDefinitionEditForm';
import TimePeriodContainer from 'risk_management/components/time_period/TimePeriodContainer';
import Packages from 'reporting/Packages';
import PackageEditor from 'reporting/PackageEditor';
import PowerBIEmbed from 'reporting/power_bi/Report';

function AppRouter() {
  return (
    <BrowserRouter>
      <Route exact
        path='/:time_period/risk_categories/new'
        component={RiskAttributeSetupContainer}
      />
      <Route exact
        path='/:time_period/risk_categories/:id/edit'
        component={RiskAttributeSetupContainer}
      />
      <Route exact
        path='/administration/accounts/:account_id/risk_entities/:risk_entity_id'
        component={DepartmentRiskEntityDefinitionsContainer}
      />
      <Route exact
        path='/administration/risk_attribute_indicator_definitions/:risk_attribute_indicator_definition/edit'
        component={RiskAttributeIndicatorDefinitionEditForm}
      />
      <Route exact
        path='/administration/risk_attribute_indicator_definitions/new'
        component={RiskAttributeIndicatorDefinitionNewForm}
      />
      <Route exact
        path='/settings/overrides/data_input_sections'
        component={DataInputSectionsContainer}
      />
      <Route exact
        path='/settings/overrides/indicator_overrides'
        component={RiskAttributeIndicatorSearchContainer} />
      <Route exact
        path='/settings/overrides/data_input_sections/:id/data_input_risk_entity_definitions'
        component={DataInputRiskEntityDefinitionsContainer} />
      <Route exact
        path='/settings/overrides/data_input_sections/search'
        component={DiredSearchResultsContainer}
      />
      <Route exact
        path='/settings/overrides/risk_category_root_definition_overrides'
        component={() => (<RiskCategoryOverridesContainer categoryRoot='true' />)}
      />
      <Route exact
        path='/settings/overrides/risk_category_overrides'
        component={() => (<RiskCategoryOverridesContainer categoryRoot='false' />)}
      />
      <Route exact
        path='/:quarter/risk_categories/:risk_category_id/attributes/:id/edit_risk_attribute_indicators'
        component={RiskAttributeIndicatorSetupContainer}
      />
      <Route exact
        path='/:time_period/risk_categories/:risk_category_id/attributes/:id/edit_self_assessment_considerations'
        component={SelfAssessmentConsiderationSetupContainer}
      />
      <Route exact
        path='/settings/overrides/indicator_overrides/risk_attribute_indicator_risk_entity_definitions'
        component={RiskAttributeIndicatorsContainer}
      />
      <Route exact
        path='/settings/overrides/indicator_overrides/risk_attribute_indicator_risk_entity_definitions/new'
        component={RiskAttributeIndicatorNewForm}
      />
      <Route exact
        path='/settings/overrides/indicator_overrides/risk_attribute_indicator_risk_entity_definitions/:id/edit'
        component={RiskAttributeIndicatorEditForm}
      />
      <Route exact
        path='/settings/overrides/attribute_overrides/:attributeOverrideID-:attributeOverrideName/risk_attributes'
        component={RiskAttributesContainer}
      />
      <Route exact
        path='/settings/overrides/assessment_overrides/self_assessment_consideration_risk_entity_definitions'
        component={SelfAssessmentConsiderationsContainer}
      />
      <Route exact
        path='/settings/call_reports'
        component={CallReportsSyncButton}
      />
      <Route exact
        path='/time_periods/new'
        component={TimePeriodContainer}
      />
      <Route exact path='/reporting/packages' component={Packages} />
      <Route exact path='/reporting/packages/:id' component={PackageEditor} />
      <Route exact path='/data_visualizations/:id' component={PowerBIEmbed} />
    </BrowserRouter>
  );
}

export default AppRouter;
